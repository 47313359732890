@font-face {
    font-family: "Barlow"; font-weight: 400;
    src: local("Barlow"),
    url("./resources/fonts/Barlow-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Barlow"; font-weight: 500;
    src: local("Barlow"),
    url("./resources/fonts/Barlow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow"; font-weight: 600;
  src: local("Barlow"),
    url("./resources/fonts/Barlow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow"; font-weight: 700;
  src: local("Barlow"),
    url("./resources/fonts/Barlow-Bold.ttf") format("truetype");
}
div.ant-upload{
  width: 100%;
}