.card {
    background-color: #020246; /* Background color for the card */
    color: #FFFFFF; /* Text color */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    padding: 10px 20px 20px 20px;
}

.transparentCard {
    background-color: #FFFFFF; /* Background color for the card */
    color: #000000; /* Text color */
    border: 4px solid #020246;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    padding: 10px 20px 20px 20px;
}

.titleText {
    text-align: left;
    font-size: 15px;
    margin: 5px 3px;
}

.mainText {
    font-size: 50px;
    margin: 24px 24px 0 24px;
}

.unitText {
    font-size: 18px;
    margin: 0px;
}

.additionalInfo {
    font-size: 10px;
    margin: 12px;
}

